.future {
  padding: 100px 0px;
  background-color: #ffffff;
}

.future h2 {
  font-size: 40px;
  margin-bottom: 20px;
  color: #363f54;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.future h3 {
  font-size: 15px;
  text-transform: uppercase;
  font-family: "poppins-bold", Sans-Serif;
  color: #363f54;
  margin-bottom: 20px;
}

.future h4 {
  font-size: 15px;
  margin-bottom: 20px;
  color: #5494DE;
  font-family: "poppins-medium", Sans-Serif;
}

.future p {
  margin-top: 20px;
  line-height: 1.5em;
  font-size: 16px;
  color: #363f54;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.future span {
  display: block;
  font-size: 14px;
  line-height: 1.75em;
  opacity: 0.7;
}

.future .benefit-wrapper {
  background-color: #f9f9f9;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 0px 1px;
}

.future .get-in-touch-button {
  display: inline-block;
  color: #ffffff;
  border-radius: 0;
  border: none;
  background-image: linear-gradient(to right, #5494de, #2b5890);
  font-family: "poppins-regular", Sans-Serif;
  padding: 20px 50px;
  margin-top: 20px;
}

.future .feature-icon {
  height: 45px;
  width: 45px;
  margin-bottom: 15px;
}

@media (min-width: 990px) {
  .future .benefit-wrapper {
    margin: 50px 0;
    padding: 50px 20px;
  }
}

@media (max-width: 989px) {
  .future .benefit-wrapper {
    padding: 25px 20px;
    margin: 15px 0;
  }
}