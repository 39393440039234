.news-post {
  height: 410px;
  background-color: #0d1218;
  text-align: left;
}

.news-post .image {
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.news-post .text {
  padding: 30px 30px 30px 30px;
  height: 175px;
}

.news-post .text .title {
  color: white;
  font-size: 16px;
}

.news-post .text .content {
  margin-top: 10px;
  color: white;
  opacity: 0.6;
  font-size: 14px;
}

.news-post:hover {
  text-decoration: none;
  background-color: #1d2336;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px, rgba(0, 0, 0, 0.15) 0px 0px 1px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px, rgba(0, 0, 0, 0.15) 0px 0px 1px;
}

.news-post .arrow {
  /* position: absolute;
  bottom: 30px;
  left: 45px; */
  clear: both;
}

.news-post .arrow-container{
  padding-left: 30px;
  position: relative;
  bottom: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .news-post .text .content {
    font-size: 12px;
  }

  .news-post .text .title {
    font-size: 14px;
  }
}

@media (max-width: 350px) {
  .news-post .text .content {
    font-size: 12px;
  }

  .news-post .text .title {
    font-size: 14px;
  }

@media (min-width: 351px) and (max-width: 400px) {
  .news-post .text .content {
    font-size: 13px;
  }

  .news-post .text .title {
    font-size: 15px;
  }
}
}


@media (max-width: 991px) {
  .news-post .text {
    height: 170px;
    }
  }
