.carousel .carousel-container {
  height: 575px;
  background-color: #0d1218 !important;
  background-image: url("../../img/hero-gradient.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: -560px -10%;
  z-index: 100;
}

.carousel .carousel-left {
  padding: 150px 50px;
  width: 60%;
  float: left;
  background-size: auto;
  background-repeat: no-repeat;
  height: 100%;
}

@media (max-width: 1200px) {
  .carousel .carousel-left {
    padding-top: 100px;
  }
}

.carousel .carousel-right {
  width: 40%;
  float: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.carousel .carousel-right.trustflight {
  background-image: url("../../img/carousel/trustflight.jpg");
}

.carousel .carousel-right.harvestid {
  background-image: url("../../img/carousel/harvest-id.jpg");
}

.carousel .carousel-right.distilledid {
  background-image: url("../../img/carousel/distilled-id.jpg");
}

.carousel .carousel-left #blurb {
  line-height: 1.4;
}

.carousel .button {
  cursor: pointer;
}

.carousel span {
  font-weight: 500;
  color: #fff;
}

.carousel .button-div {
  margin-top: 40px;
  text-align: left;
}

.carousel .learn-button {
  display: inline-block;
  color: #ffffff;
  border-radius: 0;
  border: none;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5494de),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #5494de, #2b5890);
  font-family: "poppins-regular", Sans-Serif;
  font-size: 15px;
  letter-spacing: 0.035em;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.carousel .video-button {
  display: inline-block;
  border: 1px solid #ffffff;
  background: none;
  color: #ffffff;
  border-radius: 0;
  font-family: "poppins-regular", Sans-Serif;
  letter-spacing: 0.035em;
  font-size: 15px;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 701px) {
  .carousel .narrow-timer-container {
    display: none;
  }
}

.carousel .video-button .play-icon {
  padding-right: 5px;
}

.carousel .carousel-row {
  padding-top: 50px;
}

.carousel .carousel-left h1 {
  color: #D1D1D8;
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 46px;
  text-align: left;
}

@media (max-width: 600px) {
  .carousel .carousel-right {
    width: 100%;
    height: 250px;
    float: left;
    background-position-y: 90%;
  }
}

/* styles for when the carousel is stacked */
@media (max-width: 700px) {
  .carousel .button {
    font-size: 12px;
  }

  .carousel .carousel-container {
    height: 500px;
  }

  .carousel .carousel-left {
    padding: 40px;
    width: 100%;
    height: 300px;
    float: left;
    font-size: 20px;
    z-index: 10;
    position: absolute;
  }

  .carousel .carousel-left h1 {
    font-size: 22px;
  }

  .carousel .carousel-right {
    width: 100%;
    height: 360px;
    margin-top: 175px;
    z-index: 5;
    background: #0d1218;
    background-repeat: no-repeat;
    float: left;
    background-size: contain;
    display: block;
  }

  .carousel .wide-timer-container {
    display: none;
  }


  .carousel .carousel-left #blurb {
    line-height: 1.5;
    text-align: left;
  }
}

@media (max-width: 340px) {
  .carousel .carousel-left {
    height: 380px;
  }
}

@media (min-width: 341px) and (max-width: 410px) {
  .carousel .carousel-left {
    height: 330px;
  }
}

/* Fix buttons on small screens */
@media (max-width: 330px) {
  .carousel .btn {
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .carousel .learn-button {
    margin-right: 18px;
  }
}

@media (min-width: 331px) and (max-width: 350px) {
  .carousel .btn {
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 351px) and (max-width: 395px) {
  .carousel .btn {
    font-size: 13px;
  }
}

@media (max-width: 390px) {
  .carousel .button-div {
    margin-top: 30px;
  }

  .carousel .learn-button {
    float: left;
  }

  .carousel .video-button {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 701px) and (max-width: 1010px) {
  .carousel #blurb {
    font-size: 40px;
  }
}

@media (max-width: 550px) {
  .carousel .button-div {
    margin-top: 20px;
  }
}

/* make carousel slightly larger to accomodate extra line of text */
@media (min-width: 400px) and (max-width: 440px) {
  .carousel .carousel-left {
    height: 320px;
  }
}
