.request-demo {
  font-family: "poppins-regular", Sans-Serif;
  width: 100%;
}

.request-demo .request-container {
  padding: 0px;
}

.request-demo .col-two {
  max-width: 670px;
  padding-bottom: 120px;
}

.request-demo form label {
  margin-bottom: 5px;
  font-size: 12px;
  font-family: "nanum-gothic-bold";
}

.request-demo .request-demo-row .col-one-bg .col-one input {
  margin-bottom: 0px !important;
}

.request-demo .spinner-div {
  text-align: center;
}

.request-demo .spinner {
  height: 32px;
  width: 32px;
}

.request-demo div.form-group.col {
  padding-bottom: 0px;
}

.request-demo .success {
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 24px;
}

.request-demo .error {
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 17px;
  color: #c3709d;
  margin-top: 30px;
}

@media (min-width: 1340px) {
  .request-demo .col-one {
    max-width: 670px;
    float: right;
  }
}

@media (min-width: 992px) {
  .request-demo form {
    padding-right: 50px;
  }
}

@media (min-width: 641px) and (max-width: 991px) {
  .request-demo form {
    padding-right: 100px;
  }
}

@media (min-width: 992px) and (max-height: 790px) {
  .request-demo .back-block {
    margin-top: 0px !important;
  }

  .request-demo .col-two h2 {
    margin-top: 30px !important;
  }
}

.request-demo .col-one-bg {
  background-color: #0d1218;
  height: calc(100vh - 80px);
  overflow: scroll;
}

.request-demo .col-two-bg {
  background-color: #ffffff;
}

.request-demo .arrow-left {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  content: "";
  display: inline-block;
  position: relative;
  margin-left: 0.75rem;
  vertical-align: baseline;
  width: 22px;
  height: 17px;
  background-size: cover;
  background-repeat: no-repeat;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.4s
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-image: url(../../img/icons/back-arrow.svg);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.request-demo .arrow-left:hover {
  -webkit-transform: translate(-7px, 0);
  transform: translate(-7px, 0);
}

.request-demo-row {
  /* max-width: 1440px; */
  margin: 0 0 0 0;
}

.request-demo .col-one {
  text-align: left;
  width: 100%;
  margin: 0 0 0 0;
  font-family: "nanum-gothic-regular", Sans-Serif;
  padding-left: 100px;
  padding-top: 20px;
  background-color: #0d1218;
  color: #ffffff;
}

.request-demo .col-one .demo-title {
  font-size: 30px;
  margin-bottom: 30px;
}

.request-demo .col-one .block {
  margin-right: 25%;
  margin-top: 25px;
}

.request-demo .col-one .block h3 {
  margin-bottom: 25px;
}

.request-demo .col-one .label-title {
  font-size: 12px;
  margin-bottom: 3px;
  font-weight: bold;
  font-family: "nanum-gothic-bold", Sans-Serif;
}

.request-demo .col-one .submit-button {
  cursor: pointer;
  display: inline-block;
  color: #ffffff;
  border-radius: 0;
  border: none;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5494de),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #5494de, #2b5890);
  font-family: "poppins-regular", Sans-Serif;
  font-size: 15px;
  letter-spacing: 0.035em;
  padding: 15px 75px;
  margin-right: 20px;
  margin-top: 15px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-bottom: 30px;
}

.request-demo
  .request-demo-row
  .col-one-bg
  .col-one
  input::-webkit-input-placeholder {
  color: #ffffff;
}

.request-demo
  .request-demo-row
  .col-one-bg
  .col-one
  input:-ms-input-placeholder {
  color: #ffffff;
}

.request-demo
  .request-demo-row
  .col-one-bg
  .col-one
  input::-ms-input-placeholder {
  color: #ffffff;
}

.request-demo
  .request-demo-row
  .col-one-bg
  .col-one
  input::-webkit-input-placeholder {
  color: #ffffff;
}

.request-demo
  .request-demo-row
  .col-one-bg
  .col-one
  input:-ms-input-placeholder {
  color: #ffffff;
}

.request-demo
  .request-demo-row
  .col-one-bg
  .col-one
  input::-ms-input-placeholder {
  color: #ffffff;
}

.request-demo .request-demo-row .col-one-bg .col-one input::placeholder {
  color: #ffffff;
}

.request-demo .request-demo-row .col-one-bg .col-one input {
  height: 45px;
  font-size: 14px;
  border: 1px solid #6e7175;
  margin-bottom: 20px;
  margin-right: 25%;
  border-radius: 0px;
  color: #e8e8e8;
  background-color: #0d1218;
  font-family: "nanum-gothic-regular", Sans-Serif;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.request-demo .input-group > .form-control:focus {
  border: 1px solid #f1f1f1 !important;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.request-demo .col-two {
  text-align: left;
  width: 100%;
  font-family: "nanum-gothic-regular", Sans-Serif;
  background-color: #ffffff;
  color: #0d1218;
  padding-left: 100px;
  padding-top: 40px;
  padding-right: 50px;
  height: calc(100vh - 80px);
}

.request-demo .col-two p {
  font-size: 16px;
  opacity: 0.8;
  color: #363f54;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.request-demo .col-two h2 {
  font-size: 30px;
  color: #363f54;
  margin-top: 50px;
  margin-bottom: 50px;
}

.request-demo .col-two h3 {
  font-size: 18px;
  color: #363f54;
  font-weight: bold;
  font-family: "nanum-gothic-bold", Sans-Serif;
  margin: 30px 0px 10px 0px;
}

.request-demo .col-two li {
  list-style-type: square;
  color: #4882c7;
}

@media (min-width: 0px) and (max-width: 640px) {
  .request-demo .col-one {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .request-demo .col-two {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .request-demo .col-one .block {
    margin-right: 0;
  }

  .request-demo .request-demo-row .col-one-bg .col-one input {
    margin-right: 0;
  }

  .request-demo .col-one .submit-button {
    width: 100%;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .request-demo .col-two {
    overflow: initial;
  }
}

.request-demo .invalid-feedback {
  color: #7adaf9;
}

.request-demo .was-validated .form-control:invalid,
.request-demo .form-control.is-invalid {
  border-color: #7adaf9 !important;
  fill: #7adaf9 !important;
  background-image: none;
}

/* @media (max-height: 750px) {
  .request-demo .left-col {
    min-height: 700px;
  }
} */

/* Customize the label (the container) */
.request-demo .container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.request-demo .radio-container .form-label {
  font-size: 14px;
  font-family: "nanum-gothic-regular";
  color: #e8e8e8;
  margin-right: 10px;
}

.request-demo .form-row {
  margin-bottom: 10px;
}

/* Hide the browser's default radio button */
.request-demo .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.request-demo .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 1px;
  border: 1px solid #d4d9db;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.request-demo .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.request-demo .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.request-demo .container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: #79b5fe;
}

.request-demo .radio-container {
  display: inline-block;
}

@media (max-width: 1024px) {
  .request-demo .col-one-bg {
    height: initial;
  }
}

.request-demo textarea, .request-demo textarea:active, .request-demo textarea:focus {
  background-color: #0d1218;
  border: 1px solid #6e7175;
  border-radius: 0px;
  color: #fff;
  font-size: 14px;
}

.request-demo textarea::placeholder {
  font-size: 14px;
  color: #fff;
}
