.news-template .news-heading {
  background-color: #101e2e;
  position: relative;
}

.news-template .col-header {
  padding: 0px 70px 0px 70px;
}

.news-template hr {
  background-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
  height: 3px;
  border: 0;
  position: relative;
  top: -55px;
  width: 13%;
}

.news-template .header-image {
  position: relative;
  width: 250px;
  height: 250px;
  transform: translateY(-30%);
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: cover;
  background-position: center;
}

.news-template .header {
  padding: 100px 0px 120px 0px;
}

.news-template .header h2 {
  color: #ffffff;
  font-size: 50px;
  line-height: 1.5em;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.news-template .article {
  padding-bottom: 100px;
  text-align: left;
}

.news-template .article .text-column {
  padding: 0px 50px 50px 50px;
}

.news-template .social-links {
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: "nanum-gothic-bold";
}

.news-template .social-links .social-row a:not(:first-child) {
  margin-left: 30px;
}

.news-template .social-links .link {
  border-bottom: 1px solid #5291da;
  padding-bottom: 7px;
  font-size: 16px;
}

.news-template .social-links .link:hover {
  color: #0e111a;
  border-bottom: 1px solid #0e111a;
}

.news-template .social-links h5 {
  font-size: 14px;
  font-family: "nanum-gothic-bold";
}

.news-template .border-div {
  margin-top: 0px;
  border-bottom: 1px solid #8f94a0;
  text-align: center;
  padding-bottom: 50px;
}

.news-template .footnote {
  margin-top: 50px;
  border-top: 2px solid #5494de;
  padding-top: 20px;
  font-weight: 500;
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-template hr {
    width: 18%;
  }

  .news-template .article .text-column {
    padding: 0px 0px 50px 0px;
  }
}

.news-template .text-column > p:first-child {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 30px;
}

.news-template p:not(:first-child) {
  color: #363f54;
  opacity: 0.85;
}

.news-template img:not(:first-child) {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.news-template .navigation-div {
  font-family: "nanum-gothic-bold", Sans-Serif;
}

.news-template .previous {
  float: left;
  text-align: left;
  color: #5291da;
}

.news-template .previous-inactive {
  float: left;
  text-align: left;
  color: #dddddd;
}

.news-template .next {
  text-align: right;
  float: right;
  color: #5291da;
}

.news-template .next-inactive {
  text-align: right;
  float: right;
  color: #dddddd;
}

.news-template .navigation-div {
  padding-top: 20px;
  font-size: 18px;
}

.news-template a {
  text-decoration: none;
  color: rgb(77, 103, 158);
  word-wrap: break-word;
}

.news-template a:hover {
  color: #363f54;
}

@media (max-width: 767px) {
  .news-template hr {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .news-template .header h2 {
    font-size: 30px;
  }
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .news-template .header h2 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .news-template .header h2 {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .news-template .header h2 {
    font-size: 24px;
  }
  .news-template .col-header {
    padding: 0px 20px 0px 20px;
  }
}
