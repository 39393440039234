.contact {
  font-family: "poppins-regular", Sans-Serif;
}

.contact .map {
  height: 300px;
  width: 100%;
}

.contact .map-row {
  margin: 0px 0px 50px 0px;
}

.contact .map-col {
  padding: 0px;
}

.contact .col-one {
  text-align: left;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.contact .col-one .question {
  line-height: 1.5;
}

.contact .col-one h1 {
  font-size: 30px;
}

.contact .col-one h2 {
  font-size: 24px;
  margin: 60px 0px 20px 0px;
}

.contact .col-one h3 {
  font-size: 16px;
}

.contact .col-one .block {
  margin-top: 25px;
}

.contact .col-one .block h3 {
  margin-bottom: 25px;
}

.contact .location {
  font-weight: 700;
  padding-bottom: 5px;
}

.contact .col-one .block-two {
  line-height: 50px;
}

.contact a {
  color: #5291da;
  transition: all 0.3s ease-in-out;
}

.contact a:hover {
  color: #2b5890;
  text-decoration: none;
}

.contact .col-one .block-five h3,
.contact .col-one .block-four h3,
.contact .col-one .block-three h3,
.contact .col-one .block-two h3 {
  margin-bottom: 15px;
}

.contact .contact-row {
  margin-bottom: 130px;
}

.contact .col-two label {
  color: #0d1218;
  font-size: 11px;
  font-family: "nanum-gothic-bold", Sans-Serif;
  margin-bottom: 3px;
}

.contact .col-two {
  padding-top: 35px;
  text-align: left;
}

.contact .col-two input::-webkit-input-placeholder {
  color: #474747;
}

.contact .col-two input:-ms-input-placeholder {
  color: #474747;
}

.contact .col-two input::-ms-input-placeholder {
  color: #474747;
}

.contact .col-two input::placeholder {
  color: #474747;
}

.contact .col-two input {
  width: 100%;
  height: 45px;
  font-size: 14px;
  border: 1px solid #d2d4d8;
  margin-bottom: 10px;
  border-radius: 0px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  color: #46494d;
}

.contact .col-two .text-input {
  height: 160px;
  border-radius: 0px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.contact .col-two textarea {
  font-size: 14px;
}

.contact .col-two textarea::-webkit-input-placeholder {
  color: #474747;
}

.contact .col-two textarea:-ms-input-placeholder {
  color: #474747;
}

.contact .col-two textarea::-ms-input-placeholder {
  color: #474747;
}

.contact .col-two textarea::placeholder {
  color: #474747;
}

.contact .col-two .submit-button {
  color: #ffffff;
  border-radius: 0;
  border: none;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5494de),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #5494de, #2b5890);
  font-family: "poppins-regular", Sans-Serif;
  padding: 13px 75px 13px 75px;
  margin-right: 20px;
  margin-top: 20px;
  font-size: 14px;
}

.contact .col-two .spinner-div {
  padding: 13px 75px 13px 75px;
  margin-right: 20px;
  margin-top: 20px;
}

.contact .col-two .spinner {
  height: 32px;
  width: 32px;
}

.contact .col-two .success {
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 24px;
}

.contact .col-two .error {
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 17px;
  color: #c3709d;
  margin-top: 30px;
}

.contact textarea.form-control {
  border-radius: 0px;
  font-size: 14px;
  border: 1px solid #d2d4d8;
  font-family: "nanum-gothic-regular", Sans-Serif;
  color: #46494d;
}

.contact .form-control {
  transition: all 0.5s ease-in-out;
}

.contact .form-control:focus {
  border: 1px solid #5494de;
  outline: none;
  box-shadow: none;
}

.contact .col-two {
  padding-right: 90px;
}

/* only on wide devices */

@media (max-width: 1199px) {
  .contact .col-two {
    padding-left: 15px;
  }
}

/* Customize the label (the container) */
.contact .container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contact .radio-container .form-label {
  font-size: 14px;
  font-family: "nanum-gothic-regular";
  color: #46494d;
  margin-right: 10px;
}

/* Hide the browser's default radio button */
.contact .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.contact .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 1px;
  border: 1px solid #d4d9db;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.contact .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.contact .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.contact .container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: #79b5fe;
}

.contact .radio-container {
  display: inline-block;
}

.contact #industry-row {
  padding-bottom: 25px;
}
