.modal-video {
}

.modal-video .close-icon {
  float: right;
  height: 25px;
}

.modal-video .modal-dialog {
  margin-top: 15vh !important;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 5vh;
}
.modal-video .modal-content {
  background-color: transparent;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

@media (min-width: 576px) {
  .modal-video .modal-dialog {
    max-width: 80%;
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .modal-video .close-icon {
    position: relative;
    left: 5vw;
  }
}

@media (min-width: 1600px) {
  .modal-video .close-icon {
    position: relative;
    left: -3vw;
    top: -3vh;
  }
}

@media (max-width: 1200px) {
  .modal-video .close-icon {
    position: relative;
    top: 5vh;
  }
}

@media (max-width: 1100px) {
  .modal-video .close-icon {
    position: relative;
    top: 7vh;
  }
}

@media (max-width: 1000px) {
  .modal-video .close-icon {
    position: relative;
    top: 10vh;
  }
}

@media (max-width: 800px) {
  .modal-video .close-icon {
    position: relative;
    top: 12vh;
  }
}

@media (max-width: 700px) {
  .modal-video .close-icon {
    position: relative;
    top: 15vh;
  }
}

@media (max-width: 500px) {
  .modal-video .close-icon {
    position: relative;
    top: 17vh;
  }
}

@media (max-width: 400px) {
  .modal-video .close-icon {
    position: relative;
    top: 15vh;
  }
}
