.news a:hover {
  text-decoration: none;
}

.news .border-gradient {
  margin-top: 40px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5494de),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
  width: 30%;
  height: 4px;
}

.news .featured-image {
  width: auto;
  height: 455px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.news .featured-row {
  background-color: #101e2e;
  margin-bottom: 100px;
  padding: 100px 0px 100px 0px;
}

.news .featured-row .featured-content {
  text-align: left;
  color: #ffffff;
}

.news .featured-row .featured-content h2 {
  font-size: 50px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.news .featured-row .featured-content h3 {
  font-size: 16px;
  opacity: 0.8;
  font-family: "nanum-gothic-regular", Sans-Serif;
  line-height: 1.5;
}

.news .news-row .news-item {
  margin-bottom: 20px;
}

.news .news-row .news-item h2 {
  height: 300px;
  margin-bottom: 0px;
}

.news .news-row .news-item h3 {
  margin-top: 0px;
  height: 100px;
}

.news .stay-updated-copy {
  margin-top: 30px;
}

.news .stay-updated h2 {
  margin-top: 100px;
  font-family: "poppins-regular", Sans-Serif;
  font-size: 40px;
}

.news .stay-updated-copy h3 {
  font-family: "poppins-regular", Sans-Serif;
  color: #6e717a;
  font-size: 16px;
  padding: 0px 40px 0px 40px;
  line-height: 1.5;
}

@media (min-width: 992px) {
  .news .featured-row .image-col {
    padding-left: 75px;
  }

  .news .featured-row .featured-content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .news .featured-row .featured-content {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news .featured-row .featured-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* form styling */

.news .form-row {
  margin-top: 30px;
  margin-bottom: 100px;
}

.news .form-row .form-col {
  padding: 0px 70px 0px 70px;
}

.news .form-field {
  background-color: #1d416e;
  border-radius: 2px 0px 0px 2px;
  padding-left: 20px;
  height: 65px;
  border-color: #1d416e;
  color: #ffffff;
  font-size: 15px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.news .arrow-button {
  background-color: #1d416e;
  border-radius: 0px 2px 2px 0px;
  padding-right: 20px;
  height: 65px;
  border-color: #1d416e;
}

.news .arrow-button .arrow {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.news .form-field::-webkit-input-placeholder {
  color: #ffffff;
}

.news .form-field:-ms-input-placeholder {
  color: #ffffff;
}

.news .form-field::-ms-input-placeholder {
  color: #ffffff;
}

.news .form-field::placeholder {
  color: #ffffff;
}

.news .form-field:focus,
.news .arrow-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .news .featured-content {
    margin-top: 50px;
  }

  .news .border-gradient {
    margin: auto;
    margin-top: 40px;
  }
}

@media (max-width: 500px) {
  .news .form-row .form-col {
    padding: 0px 20px 0px 20px;
  }
}
