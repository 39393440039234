.vacancy {
  font-family: "nanum-gothic-regular", Sans-Serif;
  text-align: left;
  color: #fff;
}

.vacancy .vacancy-top-bg .max-width,
.vacancy .max-width {
  max-width: 950px;
  margin: auto;
}

.vacancy .top-header-text {
  font-size: 28px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.vacancy .bottom-header-text {
  font-size: 28px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.vacancy .bottom-text {
  font-size: 16px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.vacancy .top-text {
  font-size: 16px;
  padding-bottom: 30px;
  margin: 0 auto;
}

.vacancy .vacancy-top-bg {
  background-color: #101e2e;
  color: #fff;
  width: 100%;
  margin: 0 auto;
}

.vacancy .vacancy-bottom-bg {
  background-color: #fff;
  color: #363f54;
  width: 100%;
  margin: 0 auto;
}

.vacancy .vacancy-bottom-body {
  text-align: left;
  margin: auto;
  padding: 100px 100px 100px 100px;
  max-width: 1440px;
}

.vacancy .vacancy-top-body {
  text-align: left;
  margin: auto;
  padding: 100px 100px 100px 100px;
  max-width: 1440px;
}

.vacancy .vacancy-top-bg .vacancy-top-body .apply-link {
  float: right;
}

.vacancy .vacancy-top-bg .vacancy-top-body .apply-link .apply-link-text {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.vacancy .paragraph-separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 30px 0 30px 0;
  margin: 0 auto;
}

.vacancy ul {
  font-size: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.vacancy .list-text li {
  padding-left: 16px;
}

.vacancy li:not(:last-child) {
  margin-bottom: 1.5em;
}

.vacancy .list-text li::before {
  content: "•";
  padding-right: 8px;
  color: #5291da;
}

.vacancy .end-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #6e7175;
  font-size: 18px;
  margin: 30px 20px;
  font-family: "poppins-light", Sans-Serif;
}

.vacancy .apply-button-position {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.vacancy .apply-button {
  color: #ffffff;
  border-radius: 0;
  border: none;
  background-image: -webkit-gradient(linear, left top, right top, from(#5494de), to(#2b5890));
  background-image: linear-gradient(to right, #5494de, #2b5890);
  font-family: "poppins-regular", Sans-Serif;
  padding: 12px 50px 12px 50px;
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 14px;
}

@media (min-width: 568px) and (max-width: 991px) {
  .vacancy .vacancy-bottom-body {
    text-align: left;
    padding: 100px 5% 100px 5%;
  }
  .vacancy .vacancy-top-body {
    text-align: left;
    padding: 100px 5% 100px 5%;
  }
}

@media (min-width: 0px) and (max-width: 567px) {
  .vacancy .vacancy-bottom-body {
    text-align: left;
    padding: 50px 5% 50px 5%;
  }
  .vacancy .vacancy-top-body {
    text-align: left;
    padding: 50px 5% 50px 5%;
  }
}
