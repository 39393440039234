.tab-producers {
  max-width: 1440px;
  padding-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
}

.tab-producers .featured-row .demo-inset {
  float: right;
  width: 100%;
  height: 460px;
  background-image: url(../../img/products/producers/producers-featured.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tab-producers .featured-row .get-started {
  padding: 50px;
  background-color: #0d1218;
  height: 220px;
  max-width: 245px;
  display: block;
  color: #ffffff;
  position: relative;
  float: right;
  top: 60%;
  right: 15%;
}

.tab-producers .featured-row .featured-img-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}

.tab-producers .demo-inset h5 {
  font-family: "nanum-gothic-bold", Sans-Serif;
  font-size: 18px;
  text-align: left;
}

.tab-producers .demo-inset h3 {
  font-family: "poppins-medium", Sans-Serif;
  font-size: 16px;
}

.tab-producers .featured-row .get-started .register {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: block;
}

.tab-producers .featured-row .get-started #arrow {
  width: 17px;
  transform: translate(2px, -1px);
}

.tab-producers .featured-row .content-wrapper {
  padding-left: 60px;
  color: #363f54;
  text-align: left;
}

.tab-producers .featured-row h2 {
  font-size: 37px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  padding-bottom: 10px;
}

.tab-producers h3 {
  font-size: 20px;
  line-height: 30px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.tab-producers .featured-row p {
  font-size: 16px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  line-height: 2em;
}

@media (max-width: 767px) {
  .tab-producers .featured-row {
    margin-bottom: 50px;
  }
  .tab-producers .featured-row .content-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 600px) {
  .tab-producers .featured-row .demo-inset {
    height: 250px;
  }

  .tab-producers .featured-row .get-started {
    padding: 25px;
    height: 170px;
  }

  .tab-producers .demo-inset h5 {
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .tab-producers .featured-row h2 {
    padding-top: 60px;
  }
}
