.company {
  font-family: "nanum-gothic-regular", Sans-Serif;
  text-align: left;
}

.company .company-overview-bg {
  background-color: #101e2e;
  color: #fff;
  width: 100%;
  margin: 0 auto;
}

.company h1 {
  font-size: 24px;
  line-height: 1.5em;
}

.company h3 {
  font-size: 16px;
  line-height: 1.85em;
  color: #fff;
  opacity: 0.8;
  margin-top: 25px;
}

.company .company-overview {
  text-align: left;
  margin: 0 auto;
  padding: 100px;
  max-width: 1340px;
}

.company .join-link {
  float: right;
  width: 150px;
  height: 30px;
  cursor: pointer;
}

.company .join-us {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.company .top-text {
  font-size: 16px;
  margin: 0 auto;
  padding-bottom: 25px;
}

.company .section-title h2 {
  font-size: 28px;
  margin: 0 auto;
}

.company .header-subtitle-text {
  font-size: 18px;
  margin: 0 auto;
}

.company .dark-bg .header-subtitle-darker-text {
  color: #8f94a0;
  padding-top: 20px;
}

.company p {
  font-size: 16px;
  margin: 0 auto;
}

.company p {
  font-size: 16px;
  margin: 0 auto;
}

.company .name {
  font-family: "nanum-gothic-bold", Sans-Serif;
  font-size: 18px;
  color: #363f54;
  margin-top: 5px;
  margin-bottom: 5px;
}

.company .bio {
  padding-bottom: 20px;
  font-family: Arial;
}

.company .job-title {
  color: #5291da;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.company .company-bottom-bg {
  width: 100%;
  margin: 0 auto;
}

.company .dark-bg {
  background-color: #101e2e;
  color: #fff;
  width: 100%;
  margin: 0 auto;
}

.company .company-bottom-body {
  text-align: left;
  margin: auto;
  padding: 100px;
  max-width: 1340px;
}

.company .paragraph-separator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px 0 30px 0;
  margin: 0 auto;
}

.company .end-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #6e7175;
  font-size: 22px;
  margin: 60px 0 20px 0;
  padding: 30px 0 30px 0;
  font-family: "poppins-regular", Sans-Serif;
}

.company .apply-button-position {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.company .apply-button {
  color: #ffffff;
  border-radius: 0;
  border: none;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5494de),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #5494de, #2b5890);
  font-family: "poppins-regular", Sans-Serif;
  padding: 12px 50px 12px 50px;
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 14px;
}

@media (min-width: 568px) and (max-width: 991px) {
  .company .company-bottom-body {
    text-align: left;
    padding: 40px 20px 40px 20px;
  }

  .company .company-overview {
    text-align: left;
    padding: 40px 20px 40px 20px;
  }
}

@media (min-width: 0px) and (max-width: 567px) {
  .company .company-bottom-body {
    text-align: left;
    padding: 50px 5% 50px 5%;
  }

  .company .company-overview {
    text-align: left;
    padding: 50px 5% 50px 5%;
  }

  .company .dark-bg {
    text-align: left;
    padding: 50px 5% 50px 5%;
  }
}

.company .our-team {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, minmax(220px, 10vw));
  grid-gap: 5px;
  margin: 0 auto;
}

.company .our-team .featured:nth-child(even) {
  grid-column: auto/span 2;
  -ms-grid-column-span: 2;
  -ms-grid-column: auto;
}

.company .our-team .featured:last-child,
.our-team .featured:nth-child(odd) {
  grid-column: auto/span 3;
  -ms-grid-column-span: 3;
  -ms-grid-column: auto;
}

.company .our-team .featured {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.company .our-team .img-1 {
  background-image: url(../../img/team/about-us-img-1.jpg);
}

.company .our-team .img-2 {
  background-image: url(../../img/team/about-us-img-2.jpg);
}

.company .our-team .img-3 {
  background-image: url(../../img/team/about-us-img-3.jpg);
}

.company .our-team .img-4 {
  background-image: url(../../img/team/about-us-img-4.jpg);
}

.company .our-team .img-5 {
  background-image: url(../../img/team/about-us-img-5.jpg);
}

.company .our-team .img-6 {
  background-image: url(../../img/team/about-us-img-6.jpg);
}

.company .shadow-image-left {
  -webkit-box-shadow: -20px 20px #363f54;
  box-shadow: -20px 20px #363f54;
  width: 100%;
}

.company .shadow-image-right {
  -webkit-box-shadow: 20px 20px #363f54;
  box-shadow: 20px 20px #363f54;
  width: 100%;
}

.company .leader-img {
  background-repeat: no-repeat;
  max-width: 430px;
  height: 287px;
  border-bottom: none;
}

.company .richard {
  background-image: url(../../img/team/profile-richard.jpg);
}

.company .drew {
  background-image: url(../../img/team/profile-drew.jpg);
}

@media (min-width: 0px) and (max-width: 767px) {
  .company .shadow-image-left {
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
  }

  .company .shadow-image-right {
    -webkit-box-shadow: 0px 0px;
    box-shadow: 0px 0px;
  }
  .company .join-link {
    margin-top: 15px;
  }
}

.company .separator {
  width: 55px;
  height: 3px;
  background-image: url(../../img/icons/paragraph-separator.svg);
  background-repeat: no-repeat;
}

.company .section-wrapper {
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 40px;
}

.company .leader-one {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
}

.company .leader-two {
  padding-left: 100px;
  padding-right: 100px;
}

@media (min-width: 0px) and (max-width: 991px) {
  .company .section-wrapper {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
    margin: 0 auto;
  }

  .company .leader-one {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 40px;
    margin: 0 auto;
  }

  .company .leader-two {
    padding-left: 0px;
    padding-right: 0px;
    margin: 0 auto;
  }
}

.company .tab {
  float: left;
}

.company .tab-container {
  display: inline-block;
}

.company .nav {
  width: 100%;
  padding: 60px 0px 0px 0px;
}

.company .our-values .row {
  margin: 0px auto;
}

.company .nav-link {
  color: #fff;
  width: 225px;
  padding: 0px 0px 0px 0px;
  text-align: left;
}

.company .nav-link::after {
  display: block;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#dee1e9),
    to(#dee1e9)
  );
  background-image: linear-gradient(to right, #dee1e9 0%, #dee1e9 100%);
  content: "";
  height: 2px;
  width: 100%;
  position: relative;
  bottom: -10px;
}

.company .nav-link.active {
  color: #4882c7;
}

.company .nav-link.active::after {
  display: block;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4882c7),
    to(#4882c7)
  );
  background-image: linear-gradient(to right, #4882c7 0%, #4882c7 100%);
  content: "";
  height: 2px;
  width: 100%;
  position: relative;
  bottom: -10px;
}

.company .nav-item {
  display: inline-block;
}

.company .tab-content-row {
  padding-top: 50px;
  min-height: 100px;
}

.company .our-values-body .our-values {
  margin: 0px auto;
  padding: 0;
}

.company .our-values-body {
  text-align: left;
  margin: auto;
  padding: 100px;
  max-width: 1440px;
}

.company .our-values-col {
  padding: 20px 0px 0px 0px;
}

@media (min-width: 0px) and (max-width: 991px) {
  .company .our-values .nav-link::after {
    height: 0px;
    width: 100%;
    text-decoration: none;
  }

  .company .our-values .nav-link.active::after {
    height: 0px;
    width: 100%;
    text-decoration: none;
  }

  .company .our-values .nav {
    width: 100%;
    padding: 20px 0px 0px 0px;
  }

  .company .our-values-body {
    text-align: left;
    margin: auto;
    padding: 40px 20px 40px 20px;
    max-width: 1440px;
  }

  .company .our-values-col {
    text-align: center;
  }

  .company .nav-link {
    width: auto;
  }
}

.company .careers-row {
  margin: 0px auto;
  max-width: 1440px;
  padding: 100px;
}
.company .careers-wrapper {
  margin-top: 15px;
}

.careers-wrapper p {
  margin-bottom: 15px;
}

.company .media-row {
  margin: 0px auto;
  padding: 100px;
}

.company .role-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0px;
}

.company .roles-section {
  margin-top: 50px;
}

.company .careers-link {
  font-size: 18px;
  color: inherit;
  width: 100%;
  display: inline-block;
  line-height: 4em;
  border-bottom: 1px solid #ccc;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding-left: 15px;
  margin-top: -1px;
}

.company .careers-link:hover {
  color: #205a9e;
}

.company .careers-link:first-of-type {
  border-top: 1px solid #ccc;
}

.company .careers-wrapper .arrow-link::after {
  float: right;
  margin-top: 30px;
  margin-right: 15px;
}

.company .careers-wrapper .arrow-link {
  width: 100%;
}

.company .mail-to {
  color: #205a9e;
}

.company .mail-to:hover {
  text-decoration: none;
  color: #153e6f;
}

.company .mentions-section img {
  max-height: 25px;
  max-width: 200px;
}

.company .mentions-header-row {
  margin: 0px auto;
  max-width: 1440px;
  padding: 100px 100px 100px 100px;
}

.company .mentions-section h2 {
  font-size: 28px;
  text-align: center;
  padding-bottom: 40px;
  margin: 0 auto;
}

.company .mentions-section {
  margin: 0px auto;
  max-width: 1140px;
  padding: 100px 20px;
}

.company .card-header {
  border-bottom: none;
  padding: 30px 30px 10px 30px;
}

.company .card-body {
  padding: 10px 30px;
}

.company .card-footer {
  border-top: none;
  text-align: right;
}

.company .mentions .mentions-card:hover {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
  background-color: #214f86;
  color: #fff;
}

.company .mentions-layout .mentions-card-layout {
  margin: 0px auto;
  padding: 0px 60px 0px 60px;
}

.company .mentions .mentions-card-bottom-link-layout {
  padding-top: 30px;
}

.company .mentions .mentions-card {
  min-height: 315px;
  background-color: #0d1218;
  color: #afafaf;
  border-radius: 0px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.company .mentions-intro {
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 15px;
  line-height: 1.5em;
}

.company .media-wrapper {
  margin: 0px auto;
  max-width: 800px;
  padding: 100px 50px;
  text-align: center;
}

.company .media-heading {
  font-size: 28px;
  padding-bottom: 40px;
  margin: 0 auto;
}

.company .media-wrapper span {
  font-size: 18px;
  line-height: 2em;
}

@media (min-width: 0px) and (max-width: 991px) {
  .company .careers-row {
    margin: 0px auto;
    padding: 40px 20px 40px 20px;
  }

  .company .media-wrapper {
    margin: 0px auto;
    padding: 40px 20px 40px 20px;
  }

  .company .mentions-layout {
    margin: 0px auto;
    padding: 40px 20px 40px 20px;
  }

  .company .mentions-layout .mentions-card-layout {
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
  }

  .company .mentions .mentions-card {
    border-radius: 0px 0px 0px 0px;
    background-color: #111;
    min-height: 0px;
    margin-bottom: 40px;
  }

  .company .mentions-section {
    padding: 20px;
  }
}

.company .tab-content > .tab-pane {
  max-width: 970px;
}
