.footer {
  background-image: radial-gradient(circle at 67% 68%, #242d46, #0f131d);
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 14px;
  padding-bottom: 50px;
  border-bottom: 5px solid #1d416e;
}

.footer #email-button {
  padding: 0px;
  border: 0;
  margin: 0px;
}

.footer .footer-row {
  color: #ffffff;
  font-size: 14px;
  padding-top: 20px;
}

.footer .footer-row .title {
  font-size: 14px;
  font-family: "nanum-gothic-bold", Sans-Serif;
}

.footer .footer-row .pages-wrapper .title,
.footer .footer-row .column-three .title {
  margin-bottom: 20px;
}

.footer .footer-row ul li {
  list-style: none;
  margin-top: 8px;
  opacity: 0.8;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.footer .footer-row ul li:hover {
  opacity: 1;
  color: #fff;
}

.footer .footer-row ul {
  padding: 0px;
}

.pages-wrapper {
  text-align: left;
  padding-left: 80px;
  padding-right: 0px;
}

.footer .footer-row h3 {
  font-size: 14px;
  opacity: 0.8;
}

.footer .column-one h3,
.footer .column-three h3 {
  line-height: 1.5;
  text-align: left;
}

.footer .column-three {
  padding-left: 100px;
}

.footer .column-three .title {
  text-align: left;
}

.footer .column-three input {
  border: none;
  border-radius: 2px;
  width: 100%;
  padding: 10px;
  background-color: #1d416e;
}

.footer .column-three input::-webkit-input-placeholder {
  color: #fff;
}

.footer .column-three input:-ms-input-placeholder {
  color: #fff;
}

.footer .column-three input::-ms-input-placeholder {
  color: #fff;
}

.footer .column-three input::placeholder {
  color: #fff;
}

.footer-row .column-three #join-the-team {
  margin-top: 20px;
}

.footer-row .column-three button {
  background-color: transparent;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  color: #fff;
  margin-top: 5px;
  opacity: 0.7;
  transition: all 0.5s ease-in-out;
}

.footer-row .column-three button:hover {
  opacity: 1;
}

.footer .logo-row {
  padding-top: 50px;
}

.footer .logo-col {
  text-align: left;
}

.footer .column-one .title {
  text-align: left;
}

.footer .column-one span {
  color: #fff;
  font-family: "nanum-gothic-bold", Sans-Serif;
  font-weight: bold;
  opacity: 1;
}

.footer .column-one h3 a {
  text-decoration: none;
  color: #25b6e6;
}

.footer .column-one h3 a:hover {
  color: #53cdf5;
}

.footer .column-one h4 a {
  text-decoration: none;
  color: #ffffff;
}

.footer .footer-row .column-one h4 {
  font-size: 14px;
  text-align: left;
}

.footer .column-one h3 {
  margin-bottom: 20px;
}

.footer .column-one ul li a {
  text-align: left;
  float: left;
  padding-right: 15px;
  color: #25b6e6;
  font-family: "nanum-gothic-bold", Sans-Serif;
}

.footer .column-one ul li a:hover {
  text-decoration: none;
  color: #53cdf5;
  opacity: 1;
}

.footer .footer-row ul li {
  cursor: pointer;
}

.footer #address-one {
  margin-top: 20px;
  margin-bottom: 2px;
}

.footer .input-group-text {
  background-color: #1d416e;
  border: none;
}

.footer .input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  color: #ffffff;
  font-size: 14px;
  font-size: "nanum-gothic-regular", Sans-Serif;
}

.footer .form-field:focus,
.footer .arrow-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer .input-group > .input-group-append > .input-group-text {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.footer .form-field {
  width: 60% !important;
  height: 50px;
}

@media (min-width: 769px) and (max-width: 991px) {
  .footer .pages-wrapper {
    padding-left: 60px;
  }
}

@media (max-width: 769px) {
  .footer .pages-wrapper {
    padding-left: 15px;
    padding-top: 40px;
  }

  .footer .column-three {
    padding-left: 15px;
    padding-top: 16px;
  }
}

@media (max-width: 500px) {
  .footer .pages-wrapper li {
    padding-bottom: 10px;
  }
}

.footer .pages-wrapper ul li {
  display: block;
  border-radius: 0;
  color: #fff;
  position: relative;
  padding-bottom: 5px;
  width: auto;
}
.footer .pages-wrapper ul li:before,
.footer .pages-wrapper ul li:after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 0;
}
.footer .pages-wrapper ul li:before {
  transition: width 0s ease, background 0.4s ease;
  left: 0;
  right: 0;
  bottom: 6px;
}
.footer .pages-wrapper ul li:after {
  right: 50%;
  bottom: 6px;
  background: #fff;
  transition: width 0.4s ease;
}
.footer .pages-wrapper ul li:hover:before {
  width: 50%;
  background: #fff;
  transition: width 0.4s ease;
}
.footer .pages-wrapper ul li:hover:after {
  width: 50%;
  background: 0 0;
  transition: all 0s ease;
}
