.demo-footer {
  position: fixed;
  bottom: 0px;
  background-color: #0d1218;
  width: 100%;
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 14px;
  border-top: 1px solid;
  border-top-color: #4076b8;
  height: 80px;
}

.demo-footer-bg {
  height: 120px;
}

@media (max-width: 1024px) {
  .demo-footer {
    display: none;
  }
}
