.navigation-menu {
  background-color: #0d1218;
}

.navigation-menu .dropdown-row.row {
  margin-right: 0px;
}

.navigation-menu .navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link {
  border-bottom: 3px solid transparent;
}

.navigation-menu .bg-gradient {
  background-color: #0d1218;
}

.navigation-menu .navbar-dark .navbar-nav .nav-link {
  font-family: "poppins-regular", Sans-Serif;
  color: #bebebe;
}

.navigation-menu .navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.navigation-menu .request-a-demo {
  color: #79b5fe !important;
  font-family: "poppins-medium", Sans-Serif !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navigation-menu .request-a-demo:hover {
  color: #a4ceff !important;
}

.navigation-menu .navbar-dark .navbar-toggler {
  border: none;
}

.navigation-menu .navbar-dark .navbar-toggler-icon {
  background-image: url(../../img/icons/menu-icon.svg);
}

.navbar-dark {
  height: 75px;
}

.navbar-dark .navbar-nav .nav-link {
  top: 10px;
  position: relative;
  height: 60px;
}

/*style collapsed navbar */
.navbar-collapse {
  background-color: #0d1218;
  z-index: 1;
}

.loading-bar {
  position: absolute;
  top: 73px;
  height: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(50%, #5291da),
    to(#69a8f0)
  );
  background: linear-gradient(to right, #5291da 50%, #69a8f0 100%);
  will-change: transform;
  left: 0;
  width: 100%;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  z-index: 22;
  -webkit-transform-origin: left;
  transform-origin: left;
}

.animate-bar {
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

@media (max-width: 992px) {
  .navbar-dark .navbar-brand {
    padding-bottom: 0px;
  }

  .navigation-menu .bg-gradient {
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar-collapse {
    padding-left: 8px;
    padding-right: 8px;
  }
}

/* platform dropdown underline on large screen */
@media (min-width: 993px) and (max-width: 1030px) {
  .show.dropdown.nav-item::after {
    display: block;
    content: "";
    height: 2px;
    width: 87px;
    position: relative;
    bottom: -66px;
    -webkit-transform: translateX(42%);
    transform: translateX(42%);
  }
}

@media (min-width: 1031px) {
  .show.dropdown.nav-item::after {
    display: block;
    content: "";
    height: 2px;
    width: 87px;
    position: relative;
    bottom: -66px;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media (min-width: 992px) {
  /* disable active link style on mobile */
  .navbar-dark .navbar-nav .nav-link.active {
    color: #e8e8e8;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid;
    -o-border-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
    border-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#5494de),
      to(#2b5890)
    );
    border-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
    border-image-slice: 1;
    top: 10px;
    height: 60px;
    position: relative;
  }

  .navbar-expand-lg .navbar-nav {
    width: 100%;
  }

  .navigation-menu .navbar-dark .navbar-nav .nav-link {
    margin-left: 45px;
    font-size: 15px;
  }

  .navbar-dark .navbar-nav .nav-link {
    float: left;
  }

  .navigation-menu .menu-container {
    width: 100%;
  }

  .navigation-menu .outer {
    display: inline-block;
  }

  .navigation-menu .right {
    float: right;
  }

  .navigation-menu .middle {
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .navigation-menu .navbar-nav {
    padding-left: 10px;
  }

  .navigation-menu .navbar-dark .navbar-nav .nav-link {
    font-family: "poppins-regular", Sans-Serif !important;
    font-size: 18px;
    color: #bebebe !important;
  }

  .navigation-menu .dropdown-row {
    margin-bottom: 10px;
  }

  .navigation-menu .arrow {
    display: none;
  }

  .navigation-menu .dropdown-column-content {
    padding-bottom: 20px;
    padding-left: 10px;
  }

  .navigation-menu .dropdown-column-content h1 {
    display: none;
  }

  .navigation-menu .dropdown-column h1 {
    display: none;
  }

  .navigation-menu .outer-left {
    margin-top: 20px;
  }

  .navbar-dark .navbar-nav .nav-link {
    text-align: left;
    padding: 0px 0px 10px 0px;
  }

  .navbar-dark .navbar-nav .nav-link:before {
    content: url("../../img/icons/arrow-right-nav.svg");
    padding-right: 15px;
    position: relative;
    top: 2px;
  }

  .navigation-menu .request-a-demo {
    color: white !important;
  }

  .navigation-menu .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    opacity: 0.7;
  }

  .navigation-menu .dropdown-column h1 {
    font-size: 13px;
    padding-bottom: 10px;
  }

  .navigation-menu .spacer {
    display: none;
  }

  .navigation-menu .dropdown-row {
    background-color: #0d1218;
    padding: 0px;
  }

  .navigation-menu .dropdown-menu {
    margin: 0px;
    padding: 0px;
    border: 0px;
  }

  .navigation-menu .column-two {
    padding-left: 30px;
  }
}

/* remove dropdown toggle */
.navigation-menu .dropdown-toggle::after {
  content: none;
}

/* Nav Menu dropdown custom styles */
@media (min-width: 992px) {
  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: static;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: 100%;
    border-radius: 0px;
    border: 0px;
    padding: 0px;
    -webkit-box-shadow: 0px 500px 00px 500px rgba(27, 34, 53, 0.65);
    box-shadow: 0px 500px 00px 500px rgba(27, 34, 53, 0.65);
    position: absolute;
    top: 97%;
  }

  .navigation-menu .dropdown-column {
    text-align: center;
    padding: 60px 0px 60px 0px;
  }

  .navigation-menu .dropdown-column h1 {
    color: #5190da;
    font-size: 13px;
    text-align: left;
    padding-bottom: 10px;
  }

  .navigation-menu .dropdown-row {
    background-color: #101e2e;
  }

  .navigation-menu .dropdown-column-content {
    display: inline-block;
  }

  .navigation-menu .column-one h1 {
    font-size: 15px;
    color: #ffffff;
    display: contents;
  }

  .navigation-menu .column-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navigation-menu .spacer {
    float: left;
    position: relative;
    left: -15px;
    top: 7px;
  }

  .navigation-menu .navbar-expand-lg .navbar-nav .dropdown-menu {
    -webkit-animation: fadein 0.2s ease-in-out;
    animation: fadein 0.2s ease-in-out;
  }
}

/* scale logos and arrow */

@media (max-width: 1030px) {
  .navigation-menu .image {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  .navigation-menu .dropdown-column-content .image {
    padding-right: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1030px) {
  .navigation-menu .navbar-dark .navbar-nav .nav-link {
    margin-left: 37px;
  }
}

@media (min-width: 992px) and (max-width: 1339px) {
  .navbar-brand a {
    padding-left: 0px;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .navigation-menu .dropdown-column-content .image {
    float: left;
    padding-right: 5px;
    margin-top: 2px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}

/* ipad arrow fix */
@media (min-width: 992px) {
  .dropdown-menu #dropdown-food {
    min-width: 130px;
  }

  .dropdown-menu #dropdown-spirits {
    min-width: 142px;
  }

  .dropdown-menu #dropdown-aviation {
    min-width: 149px;
  }
}
