.privacy {
  font-family: "nanum-gothic-regular", Sans-Serif;
  text-align: left;
}

.privacy .privacy-overview-bg {
  background-color: #101e2e;
  color: #fff;
}

.privacy h1 {
  font-size: 24px;
  line-height: 1.5em;
}

.privacy h3 {
  font-size: 16px;
  line-height: 1.85em;
  color: #fff;
  opacity: 0.8;
  margin-top: 25px;
}

.privacy .privacy-overview {
  margin: 0 auto;
  padding: 100px 0;
  max-width: 650px;
  display: block;
}

.privacy .policy-wrapper {
  max-width: 650px;
  margin: auto;
  padding: 100px 0;
}

.privacy .section-wrapper {
  padding-top: 35px;
}

.privacy ul {
  list-style: none;
  padding: 0;
}

.privacy li::before {
  content: "\2022";
  color: #5291da;
  font-size: 30px;
  position: relative;
  top: 3px;
}

.privacy li {
  padding-left: 15px;
}
