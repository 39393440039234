.clients {
  padding-top: 100px;
}

.clients .client-img {
  max-width: 100%;
  height: auto;
  width: 262px;
  height: 182px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}

.clients .lot {
  background-image: url(../../img/client-logos/company-gallery/client-lot-plain.jpg);
}

.clients .lot:hover {
  background-image: url(../../img/client-logos/company-gallery/client-lot-colour.jpg);
}

.clients .ms {
  background-image: url(../../img/client-logos/company-gallery/client-ms-plain.jpg);
}

.clients .ms:hover {
  background-image: url(../../img/client-logos/company-gallery/client-ms-colour.jpg);
}

.clients .ab {
  background-image: url(../../img/client-logos/company-gallery/client-ab-plain.jpg);
}

.clients .ab:hover {
  background-image: url(../../img/client-logos/company-gallery/client-ab-colour.jpg);
}

.clients .wgs {
  background-image: url(../../img/client-logos/company-gallery/client-wgs-plain.jpg);
}

.clients .wgs:hover {
  background-image: url(../../img/client-logos/company-gallery/client-wgs-colour.jpg);
}

.clients .cranswick {
  background-image: url(../../img/client-logos/company-gallery/client-cranswick-plain.jpg);
}

.clients .cranswick:hover {
  background-image: url(../../img/client-logos/company-gallery/client-cranswick-colour.jpg);
}

.clients .hendrix {
  background-image: url(../../img/client-logos/company-gallery/client-hendrix-plain.jpg);
}

.clients .hendrix:hover {
  background-image: url(../../img/client-logos/company-gallery/client-hendrix-colour.jpg);
}

.clients .basf {
  background-image: url(../../img/client-logos/company-gallery/client-basf-plain.jpg);
}

.clients .basf:hover {
  background-image: url(../../img/client-logos/company-gallery/client-basf-colour.jpg);
}

.clients .downstream {
  background-image: url(../../img/client-logos/company-gallery/client-downstream-plain.jpg);
}

.clients .downstream:hover {
  background-image: url(../../img/client-logos/company-gallery/client-downstream-colour.jpg);
}

.clients .icb {
  background-image: url(../../img/client-logos/company-gallery/client-icb-plain.jpg);
}

.clients .icb:hover {
  background-image: url(../../img/client-logos/company-gallery/client-icb-colour.jpg);
}

.clients .kininvie {
  background-image: url(../../img/client-logos/company-gallery/client-kininvie-plain.jpg);
}

.clients .kininvie:hover {
  background-image: url(../../img/client-logos/company-gallery/client-kininvie-hover.jpg);
}

.clients .pwc {
  background-image: url(../../img/client-logos/company-gallery/client-pwc-plain.jpg);
}

.clients .pwc:hover {
  background-image: url(../../img/client-logos/company-gallery/client-pwc-colour.jpg);
}

.clients .dhl {
  background-image: url(../../img/client-logos/company-gallery/client-dhl-plain.jpg);
}

.clients .dhl:hover {
  background-image: url(../../img/client-logos/company-gallery/client-dhl-colour.jpg);
}

.clients .tag {
  background-image: url(../../img/client-logos/company-gallery/client-tag-plain.jpg);
}

.clients .tag:hover {
  background-image: url(../../img/client-logos/company-gallery/client-tag-colour.jpg);
}

.clients .wa {
  background-image: url(../../img/client-logos/company-gallery/client-wa-plain.jpg);
}

.clients .wa:hover {
  background-image: url(../../img/client-logos/company-gallery/client-wa-colour.jpg);
}

.clients .you {
  background-image: url(../../img/client-logos/company-gallery/client-your-company-plain.jpg);
}

.clients .you:hover {
  background-image: url(../../img/client-logos/company-gallery/client-your-company-colour.jpg);
}

.clients .one-fourth.column {
  width: 23%;
}

.clients .column {
  float: left;
  margin: 0 1% 10px;
}

.clients .hover-box-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  line-height: 0;
  max-width: 100%;
}

@media screen and (max-width: 1005px) and (min-width: 600px) {
  .clients .client-img {
    height: 100px;
  }
}

@media screen and (max-width: 600px) {
  .clients .client-img {
    height: 80px;
  }
  .clients .column {
    float: left;
    margin: 0 1% 0px;
  }
}
