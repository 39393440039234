/* inset */
.tab-food .featured-row .harvest-inset {
  float: right;
  width: 100%;
  height: 460px;
  background-image: url(../../img/products/harvest-id/harvest-id-featured@2x.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* "Get started with" inset */

.tab-food .featured-row .get-started {
  padding: 50px;
  background-color: #0d1218;
  height: 220px;
  display: block;
  color: #ffffff;
  position: relative;
  float: right;
  top: 60%;
  right: 15%;
}

.tab-food .harvest-inset h5 {
  font-family: "nanum-gothic-bold", Sans-Serif;
  font-size: 18px;
}

.tab-food .harvest-inset h3 {
  font-family: "poppins-medium", Sans-Serif;
  font-size: 16px;
}

.tab-food .featured-row .get-started .register {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: block;
}

.tab-food .featured-row .get-started #arrow {
  width: 17px;
  transform: translate(2px, -1px);
}

.tab-food .featured-row .harvest-logo {
  width: 65%;
}

/*      */
.tab-food .max-width {
  max-width: 1440px;
}

.tab-food .featured-row {
  margin-bottom: 160px;
  text-align: left;
}

.tab-food .featured-row .featured-img-wrapper {
  padding-right: 0px;
  padding-left: 0px;
}

.tab-food .featured-row .copy {
  padding-left: 60px;
  color: #363f54;
}

.tab-food .featured-row h2 {
  font-size: 37px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  padding-bottom: 10px;
}

.tab-food .copy h3 {
  font-size: 20px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.tab-food .featured-row p {
  font-size: 16px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  line-height: 2;
}

.tab-food .featured-row #trusted {
  font-size: 16px;
  font-family: "nanum-gothic-bold", Sans-Serif;
  padding-bottom: 10px;
}

.tab-food .featured-row img {
  width: 100%;
}

.tab-food .field-fork-row {
  margin-bottom: 50px;
}

.tab-food .field-fork-row img {
  float: right;
  height: 105%;
}

.tab-food .field-fork-row .copy {
  text-align: left;
  color: #363f54;
  padding-top: 20px;
}

.tab-food .field-fork-row .copy h2 {
  font-size: 30px;
  padding-right: 130px;
  padding-bottom: 10px;
}

.tab-food .field-fork-row p {
  font-size: 16px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.tab-food .field-fork-row #field-image {
  float: right;
  width: 100%;
  height: 430px;
  background-image: url(../../img/products/harvest-id/harvest-id-field-to-fork.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tab-food .consumer-row {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: left;
}

.tab-food .consumer-row #consumer-image {
  float: right;
  width: 100%;
  height: 475px;
  background-image: url(../../img/products/harvest-id/honey-featured.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tab-food .consumer-row h2 {
  font-size: 30px;
  padding-bottom: 10px;
  margin-top: 35px;
}

.tab-food .consumer-row .first-para,
.tab-food .field-fork-row .first-para {
  font-family: "poppins-regular", Sans-Serif;
  font-size: 18px;
  padding-top: 40px;
}

.tab-food .border-gradient {
  background-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
  width: 25%;
  height: 4px;
}

.tab-food .consumer-row #honey-image {
  position: relative;
  top: 20%;
  left: 28%;
  width: 73%;
  height: 111%;
  background-image: url(../../img/products/harvest-id/honey-app-demo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* -webkit-transform: translateZ(-800px) perspective(872px) rotateY(-339deg) scale(1) translateY(-21.6125px);
  transform: translateZ(-800px) perspective(872px) rotateY(-352deg) scale(1) translateY(-21.6125px); */
}

.tab-food .consumer-row .copy {
  padding-top: 20px;
  text-align: left;
  color: #363f54;
}

.tab-food .consumer-row .copy h2 {
  padding-bottom: 0px;
  font-family: "poppins-regular", Sans-Serif;
  font-size: 20px;
}

.tab-food .consumer-row p {
  font-size: 16px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.tab-food .platform-row {
  margin-bottom: 100px;
}

.tab-food .platform-row img {
  float: right;
  width: 100%;
}

.tab-food .platform-row .copy {
  padding-top: 100px;
  text-align: left;
  color: #363f54;
}

.tab-food .platform-row .copy h2 {
  padding-bottom: 0px;
  font-family: "poppins-regular", Sans-Serif;
  font-size: 20px;
}

.tab-food .platform-row p {
  font-size: 16px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

/* logos */
.tab-food .featured-row .logos {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 30px;
  margin-bottom: 50px;
}

.tab-food .logos .logo {
  width: 80%;
  transform: scale(0.9);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.tab-food .logos #logo1 {
  background-image: url(../../img/client-logos/harvest-id/pwc-logo.svg);
}

.tab-food .logos #logo2 {
  background-image: url(../../img/client-logos/harvest-id/hendrix-logo.svg);
}

.tab-food .logos #logo3 {
  background-image: url(../../img/client-logos/harvest-id/ms-logo.svg);
}

.tab-food .logos #logo4 {
  background-image: url(../../img/client-logos/harvest-id/cranswick-logo.svg);
}

.tab-food .logos #logo5 {
  background-image: url(../../img/client-logos/harvest-id/basf-logo.png);
}

/* styles for narrow screens */

@media (max-width: 767px) {
  .tab-food .featured-row {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    /* padding */
    .tab-food .featured-row .copy {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

/* Styles for narrow screens */
@media (max-width: 991px) {
  .tab-food .platform-row .copy {
    padding-left: 50px;
  }
}

/* Styles for wide screens */
@media (min-width: 992px) {
  /* padding */
  .tab-food .featured-row h2 {
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .tab-food .consumer-row .right-col {
    padding-left: 60px;
  }
  .tab-food .field-fork-row .copy {
    padding-right: 60px;
  }
}

@media (max-width: 992px) {
  .tab-food .consumer-row #consumer-image {
    height: 310px;
  }
}

@media (max-width: 600px) {
  .tab-food .featured-row .harvest-inset {
    height: 250px;
  }

  .tab-food .featured-row .get-started {
    padding: 25px;
    height: 150px;
  }

  .tab-food .field-fork-row #field-image {
    height: 250px;
    margin-top: 20px;
  }

  .tab-food .field-fork-row {
    margin-bottom: 15px;
  }

  .tab-food .consumer-row {
    margin: 30px 0;
  }

  .tab-food .consumer-row #consumer-image {
    height: 250px;
  }

  .tab-food .platform-row .copy {
    padding: 50px 30px 20px 30px;
  }

  .tab-food .platform-row {
    margin-bottom: 25px;
  }
}
