.feature {
  border-top: 1px solid rgba(143, 148, 160, 0.5);
  padding-bottom: 130px;
}

.feature .feature-row {
  max-width: 1340px;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
}

.feature .squareIcon {
  -webkit-transform: scale(1.5) translateX(20%);
          transform: scale(1.5) translateX(20%);
  padding-bottom: 15px;
}

.feature .feature-row .col-md-4 {
  padding: 20px 30px 20px 30px;
  text-align: left;
}

.feature .feature-row h2 {
  font-size: 18px;
  font-family: "poppins-medium", Sans-Serif;
  padding-bottom: 0px;
}

.feature .feature-row h3 {
  font-size: 14px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  line-height: 1.5;
  color: #363f54;
}

@media (min-width: 768px) and (max-width: 991px) {
  .feature {
    padding-bottom: 50px;
  }
}

@media (min-width: 451px) and (max-width: 500px) {
  .feature .feature-row .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 450px) {
  .feature .feature-row {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .feature {
    padding-bottom: 50px;
  }
}
