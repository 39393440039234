.platform {
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.platform .platform-overview-bg {
  background-color: #101e2e;
  color: #fff;
  width: 100%;
  margin: 0 auto;
}

.platform .platform-body {
  text-align: left;
  margin: 0 auto;
  padding: 100px;
  max-width: 1340px;
}

.platform .platform-body-row {
  text-align: left;
  margin: 0 auto;
  padding: 50px 100px;
  max-width: 1340px;
}

.platform .heading-wrapper {
  text-align: left;
  margin: 0px auto;
  padding: 0px 0px 50px 0px;
  max-width: 1140px;
}

.platform .left-col {
  padding-right: 20px;
}

.platform .unique-features {
  text-align: left;
  margin: 0px auto;
  max-width: 1140px;
}

.platform p {
  font-size: 16px;
  line-height: 1.8em;
  margin: 20px 0 0 0;
}

.platform h1 {
  font-size: 24px;
  margin: 0 auto;
  line-height: 1.5em;
}

.platform .smaller-text {
  font-size: 16px;
  line-height: 1.85em;
  color: #fff;
  opacity: 0.8;
  margin-top: 25px;
}

.platform .header-title-text {
  font-size: 38px;
  line-height: 1.3em;
  margin: 0 auto;
}

.platform .header-subtitle-text {
  font-size: 18px;
  margin: 0 auto;
}

.platform .blue-text {
  color: #5291da;
  margin-top: 5px;
  margin-bottom: 20px;
}

.platform .dark-blue-bold-text {
  font-family: "nanum-gothic-bold", Sans-Serif;
  color: #363f54;
  margin-top: 5px;
  margin-bottom: 5px;
}

.platform .dark-bg {
  background-color: #101e2e;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
}

.platform .platform-key-features {
  background-color: #fff;
  color: #363f54;
  width: 100%;
  margin: 50px auto 100px auto;
}

.platform .join-link {
  float: right;
  width: 150px;
  height: 30px;
}

.platform .join-us {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.platform .img-wrapper {
  padding: 50px 0;
}

.platform .blockchain-img,
.platform .integration-img,
.platform .iot-img {
  width: 270px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.platform .blockchain-img {
  background-image: url("../../img/platform/blockchain-illustration.svg");
}

.platform .integration-img {
  background-image: url("../../img/platform/integration.svg");
}

.platform .iot-img {
  background-image: url("../../img/platform/iot.svg");
}

.platform .image-layout-left {
  margin: 0px auto;
  max-width: 100%;
  float: left;
}

.platform ul {
  font-size: 18px;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 3px;
}

.platform ul li {
  list-style-position: inside;
  text-indent: -30px;
  margin-bottom: 10px;
}

.platform img {
  max-width: 100%;
  height: auto;
}

.platform .list-text li::before {
  content: "\2022";
  color: #5291da;
  font-size: 30px;
  position: relative;
  top: 3px;
}

.platform .list-text li {
  padding-left: 16px;
}

.platform .heading-wrapper h1 {
  text-align: center;
  font-size: 28px;
  font-family: "nanum-gothic-bold", Sans-Serif;
}

.platform .regular-text-center {
  text-align: center;
}

.platform .border-gradient {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5494de),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
  width: 8%;
  height: 3px;
  margin-top: 20px;
}

.platform .border-center {
  margin: auto;
  margin-top: 25px;
}

.platform .image-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.platform .card-image-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.platform .card-text-center {
  text-align: center;
  padding-top: 10px;
}

.platform .card-text-center h3 {
  font-size: 20px;
  font-family: "nanum-gothic-bold", Sans-Serif;
}

.platform .card-dark {
  margin: 0px auto;
  min-height: 350px;
  border-radius: 0px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(51, 81, 119, 0.25)),
    to(rgba(23, 40, 66, 0.25))
  );
  background: linear-gradient(
    to bottom,
    rgba(51, 81, 119, 0.25),
    rgba(23, 40, 66, 0.25)
  );
  margin-bottom: 20px;
}

.platform .card-dark .card-body {
  padding-left: 25px;
  padding-right: 25px;
}

.platform .card-regular-text {
  text-align: center;
  opacity: 0.8;
  color: fff;
  font-size: 16px;
}

.platform .card-grey {
  margin: 0px auto;
  height: 320px;
  border-radius: 0px;
  border-color: #f2f2f2;
  background-color: #f2f2f2;
  margin-bottom: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.platform .card-grey .card-text-center {
  margin-bottom: 0px;
}

.platform .card-grey h3 {
  font-size: 20px;
  font-family: "poppins-medium", Sans-Serif;
}

.platform .card-container-tight {
  max-width: 85%;
  float: right;
  text-align: center;
}

.platform .card-container-tight-left {
  max-width: 85%;
  float: left;
  text-align: center;
}

.platform .text-tight-layout {
  width: 60%;
}

.platform .register-button {
  color: #ffffff;
  border-radius: 0;
  border: none;
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5494de),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #5494de, #2b5890);
  font-family: "poppins-regular", Sans-Serif;
  padding: 20px 75px;
  margin-top: 20px;
  font-size: 14px;
}

.platform .form-layout {
  width: 80%;
  padding-right: 42px;
  margin: 0px auto;
}

.platform .form-label {
  margin-bottom: 5px;
}

.platform .form-layout input {
  border-radius: 0px;
  font-size: 14px;
  padding-top: 22px;
  padding-bottom: 22px;
  margin-bottom: 20px;
}

.platform .form-control:focus {
  border: 1px solid #5494de;
  outline: none;
  box-shadow: none;
}

.platform .form-control::-webkit-input-placeholder {
  color: #6b6f79;
}

.platform .form-control::-ms-input-placeholder {
  color: #6b6f79;
}

.platform .form-control::-ms-input-placeholder {
  color: #6b6f79;
}

.platform .form-control:-ms-input-placeholder {
  color: #6b6f79;
}

.platform .form-control::placeholder {
  color: #6b6f79;
}

.platform .form-label {
  font-size: 12px;
  font-weight: bold;
  font-family: "nanum-gothic-bold", Sans-Serif;
}

.platform .bg-logo {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.2;
}

.platform .features-container {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: left;
}

.platform .features-container h2 {
  font-size: 32px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.platform .features-container p {
  font-size: 18px;
}

.platform .features-container h3 {
  font-size: 20px;
  font-family: "poppins-medium", Sans-Serif;
  margin-top: 25px;
}

.platform .features-container .logo-wrapper {
  margin-bottom: 25px;
  margin-top: 15px;
}

.platform .features-container .logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  margin-right: 25px;
  height: 35px;
  width: 35px;
}

.platform .features-container .logo-wrapper #gcp {
  background-image: url(../../img/platform/gcp.svg);
}

.platform .features-container .logo-wrapper #aws {
  background-image: url(../../img/platform/aws.svg);
}

.platform .features-container .logo-wrapper #azure {
  background-image: url(../../img/platform/azure.svg);
}

.platform .features-container .logo-wrapper #ethereum {
  background-image: url(../../img/platform/ethereum.svg);
}

.platform .features-container .logo-wrapper #multichain {
  background-image: url(../../img/platform/mutlichain.png);
}

.platform .features-container .logo-wrapper #hyperledger {
  background-image: url(../../img/platform/hyperledger.png);
}

.platform .header-wrapper {
  padding-top: 50px;
  padding-bottom: 75px;
}

.platform .header-wrapper .header-row h2 {
  text-align: center;
  font-size: 28px;
}

.platform .solutions-wrapper {
  max-width: 1140px;
}

.platform .gradient-box {
  background: linear-gradient(120deg, #54b2f6 25%, #136baa 75%);
}

.platform .gradient-column {
  padding-left: 0px;
  padding-right: 0px;
}

.platform .solution-row .inner:hover {
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
}

.platform .solution-row .inner {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #fff;
  padding: 35px;
  padding-right: 0px;
  padding-left: 0px;
  min-height: 132px;
  transition: all 0.2s ease-in;
}

.platform .inner h4 {
  font-size: 14px;
  padding-bottom: 10px;
  font-family: "nanum-gothic-bold", Sans-Serif;
  text-transform: uppercase;
  color: #fff;
}

.platform .contact-wrapper {
  text-align: left;
  max-width: 1140px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.platform .contact-wrapper h2 {
  font-family: "poppins-regular", Sans-Serif;
  font-size: 40px;
}

.platform .contact-wrapper h4 {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1.6em;
  color: #445161;
}

.platform .contact-wrapper span {
  color: #136baa;
}

.platform .spinner-div {
  text-align: center;
}

.platform .spinner {
  height: 32px;
  width: 32px;
}

.platform .success {
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 24px;
}

.platform .error {
  font-family: "nanum-gothic-regular", Sans-Serif;
  font-size: 17px;
  color: #c3709d;
  margin-top: 30px;
}

.platform .form-control {
  border-radius: 0px;
  padding-top: 22px;
  padding-bottom: 22px;
}

.platform .block-clients .companies {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0px;
}

.platform .block-clients {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.platform .block-clients .wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 1005px) {
  .platform .logo {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  .block-clients .companies li {
    padding: 0.5rem 0;
    -webkit-justify-content: center;
    justify-content: flex-start;
    display: flex;
    background-size: contain;
  }
  .block-clients .companies {
    width: 100%;
  }
  .platform .wgs-logo {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  .platform .blockchain-img,
  .platform .integration-img,
  .platform .iot-img {
    width: 150px;
    height: 150px;
  }
}

.platform .logo {
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-align: center;
}

.platform .pwc-logo {
  background-image: url(../../img/client-logos/platform/pwc-logo.svg);
  width: 41px;
  height: 31px;
}

.platform .hendrix-logo {
  background-image: url(../../img/client-logos/platform/hendrix-genetics-logo.svg);
  width: 120px;
  height: 29px;
}

.platform .ms-logo {
  background-image: url(../../img/client-logos/platform/ms-logo.svg);
  width: 80px;
  height: 31px;
}

.platform .wgs-logo {
  background-image: url(../../img/client-logos/platform/wgs-logo.svg);
  width: 200px;
  height: 28px;
}

.platform .cranswick-logo {
  background-image: url(../../img/client-logos/platform/cranswick-logo.svg);
  width: 112px;
  height: 32px;
}

.platform .lot-logo {
  background-image: url(../../img/client-logos/platform/lot-logo.svg);
  width: 55px;
  height: 35px;
}

@media (max-width: 500px) {
  .platform .header-title-text {
    font-size: 30px;
  }

  .platform .dark-bg {
    padding-top: 50px;
  }
}

@media (max-width: 750px) {
  .platform .dark-bg {
    padding-bottom: 50px;
  }

  .platform .platform-body-row {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .platform #fantastic {
    padding-top: 20px;
  }

  .platform form {
    padding-left: 100px;
  }
}

@media (max-width: 991px) {
  .platform .solution-row {
    padding-bottom: 0px;
  }

  .platform .features-container {
    padding-bottom: 0px;
  }

  .platform .solutions-wrapper .header-wrapper {
    padding: 0px;
    padding-bottom: 20px !important;
  }

  .platform .contact-wrapper {
    margin-bottom: 0px;
  }

  .platform .unique-features .card-dark {
    margin-bottom: 50px;
  }

  .platform .unique-features .card-grey {
    margin-bottom: 50px;
  }

  .platform .platform-body-row,
  .platform .img-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .platform .image-layout {
    margin-top: 20px;
  }

  .platform .platform-body {
    text-align: left;
    padding: 40px 20px 40px 20px;
  }

  .platform .heading-wrapper {
    text-align: left;
    padding: 20px 5% 20px 5%;
  }

  .platform .unique-features {
    text-align: left;
    padding: 20px 5% 20px 5%;
  }

  .platform .card-dark {
    margin: 0px auto;
    height: unset;
    border-radius: 0px;
    margin-bottom: 20px;
    padding: 20px;
  }

  .platform .card-grey {
    margin: 0px auto;
    height: unset;
    border-radius: 0px;
    border-color: #f2f2f2;
    background-color: #f2f2f2;
    margin-bottom: 20px;
  }

  .platform .card-container-tight {
    max-width: 100%;
    float: none;
    text-align: center;
  }

  .platform .card-container-tight-left {
    max-width: 100%;
    float: none;
    text-align: center;
  }

  .platform .text-tight-layout {
    width: 100%;
  }

  .platform .form-layout {
    width: 100%;
    margin: 0px auto;
  }

  .platform .join-link {
    margin-top: 15px;
  }
  .platform .solutions-wrapper {
    margin-top: 75px;
  }
}
