.brand-protection {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#eeeeee));
  background-image: linear-gradient(to bottom, #ffffff, #eeeeee);
  padding-bottom: 50px;
}

.brand-protection .brand-protection-row {
  font-size: 50px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.brand-protection .brand-protection-row h2 {
  font-size: 38px;
}

.brand-protection .brand-protection-row h3 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 1.35em;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.brand-protection .brand-protection-row {
  padding-top: 100px;
}

.brand-protection .card-row .card-col {
  padding: 30px 20px;
  text-align: left;
  overflow: hidden;
}

.brand-protection .card-row .card-col .card-content h3 {
  padding-top: 10px;
  color: #ffffff;
  font-family: "nanum-gothic-bold", Sans-Serif;
  font-size: 18px;
}

.brand-protection .card-row .card-col h4 {
  color: #acacac;
  font-size: 14px;
  font-family: "poppins-regular", Sans-Serif;
  line-height: 1.75em;
  padding-top: 5px;
}

.brand-protection .card-row .card-col .card-content .card-text  {
  text-align: center;
}

.brand-protection .card-row .card-col .card-content {
  background-color: #0d1218;
  padding: 25px;
}

.brand-protection .feature-icon {
  margin-bottom: 15px;
}

.brand-protection .more-row h3{
  font-family: 'poppins-bold';
  font-size: 14px;
  margin-top: 20px;
}

.brand-protection .more-row .more-arrow {
  padding-left: 10px;
}

/* custom styles for card ons mobile devices */

@media (max-width: 991px) {
  .brand-protection .card-row .card-col .card-content {
    height: initial;
  }

  .brand-protection .card-col .arrow-div {
    position: initial;
    bottom: initial;
  }

  .brand-protection .card-row .card-col {
    padding: 10px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .brand-protection .card-row .card-col .card-content {
    height: 470px;
  }

  .brand-protection .card-row .card-col .card-content h4 {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .brand-protection .card-row .card-col .card-content {
    min-height: 370px;
  }

  .brand-protection .card-row .card-col .card-content .card-text{
    min-height: 290px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .brand-protection .card-row .card-col .card-content .card-text{
    min-height: 380px;
  }
}

/*
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
} */
