.your-journey {
  padding: 100px 0px 100px 0px;
  background-color: #0d1218;
}

.your-journey .line {
  background-color: #4882c7;
  height: 1px;
  width: 100%;
  position: relative;
  top: 15px;
}

.your-journey h3 {
  text-align: left;
  color: #fff;
  font-size: 26px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  padding-bottom: 20px;
}

.your-journey h4 {
  text-align: left;
  color: #fff;
  font-size: 18px;
  font-family: "nanum-gothic-regular", Sans-Serif;
  padding-bottom: 20px;
}

.your-journey .content-column {
  width: 33%;
  float: left;
  color: white;
}

.your-journey .content-column .number {
  float: left;
  border: 2px solid #4882c7;
  color: #4882c7;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  font-size: 14px;
  position: relative;
  z-index: 1;
  background-color: #0d1218;
  font-family: "nanum-gothic-bold", Sans-Serif;
  padding-top: 3px;
}

.your-journey .content-column h4 {
  color: #4882c7;
  font-family: "nanum-gothic-bold", Sans-Serif;
  font-size: 17px;
  padding: 20px 0px 5px 0px;
  clear: left;
}

.your-journey .content-column p {
  text-align: left;
  padding-right: 40px;
  color: #c8cdda;
  font-size: 14px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.your-journey a {
  color: #4882c7;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.your-journey a:hover {
  text-decoration: none;
  color: #A4CEFF;
}

@media (max-width: 575px) {
  .your-journey .content-column {
    float: none;
  }

  .your-journey .content-column {
    width: 100%;
  }

  .your-journey .line {
    display: none;
  }

  .your-journey .content-column h4 {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .your-journey .content-column p {
    padding-bottom: 30px;
    margin-bottom: 0px;
  }

  .your-journey h4 {
    padding-bottom: 0px;
  }

  .your-journey {
    padding: 50px 10px;
  }
}
