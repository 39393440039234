.icon-row .block-clients {
  width: 100%;
}

.icon-row .row {
  background-color: #0D1218
}

.icon-row #icon-row-light {
  background-color: #fff;
}

nav ul,
nav ol,
ul.row,
ol.row {
  list-style: none;
}
ul.row,
ul.row,
ol.row,
ol.row {
  padding-left: 0;
}

.icon-row .logo {
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-align: center;
}

.icon-row .logo-wrapper {
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.icon-row .block-clients .companies {
  justify-content: space-between;
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px;
}

.icon-row .block-clients .wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 411px) {
  .icon-row .wgs-logo {
    width: 160px !important;
  }
}

@media screen and (max-width: 1005px) {
  .icon-row .logo {
    transform: scale(0.7);
  }
  .block-clients .companies li {
    width: 50% !important;
    padding: 0.5rem 0;
    justify-content: center;
  }
  .block-clients .companies {
    width: 100%;
  }
}

.icon-row section .wrapper {
  padding: 0 0;
}

.icon-row .wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.icon-row .pwc-logo {
  background-image: url(../../img/client-logos/icon-row/pwc-logo.svg);
  width: 41px;
  height: 31px;
}

.icon-row .hendrix-logo {
  background-image: url(../../img/client-logos/icon-row/hendrix-logo.svg);
  width: 120px;
  height: 29px;
}

.icon-row .ms-logo {
  background-image: url(../../img/client-logos/icon-row/ms-logo.svg);
  width: 80px;
  height: 31px;
}

.icon-row .wgs-logo {
  background-image: url(../../img/client-logos/icon-row/wgs-logo.svg);
  width: 200px;
  height: 28px;
}

.icon-row .cranswick-logo {
  background-image: url(../../img/client-logos/icon-row/cranswick-logo.svg);
  width: 88px;
  height: 25px;
}

.icon-row .basf-logo {
  background-image: url(../../img/client-logos/icon-row/basf-logo.png);
  width: 80px;
  height: 30px;
}