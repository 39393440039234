.tab-distilleries {
  background: linear-gradient(to bottom, #fff, #f9f9f9);
}

.tab-distilleries .container {
  max-width: 1440px;
}

.tab-distilleries p {
  color: #363f54;
}

.tab-distilleries .arrow-link-wrapper {
  margin-top: 25px;
}

.tab-distilleries .arrow-link.dark {
  font-family: "poppins-medium";
  font-weight: 800;
}

.tab-distilleries .quote-div {
  background-image: linear-gradient(70deg, #5494de, #2b5890);
  padding-top: 120px;
  padding-bottom: 120px;
  color: #ffffff;
  text-align: left;
}

.tab-distilleries .quote-div h2 {
  font-size: 26px;
  font-family: "poppins-regular", Sans-Serif;
  line-height: 1.5;
}

.tab-distilleries .quote-div h3 {
  font-family: "poppins-regular", Sans-Serif;
  font-size: 19px;
}

.tab-distilleries .explore-partnerships {
  max-width: 1300px;
  margin: auto;
  text-align: left;
  font-size: 18px;
  font-family: "poppins-medium", Sans-Serif;
  color: #0d1218;
}

.tab-distilleries .explore-col {
  padding-left: 30px;
}

.tab-distilleries .partnership-row {
  max-width: 1300px;
  margin: auto;
  padding-bottom: 150px;
}

.tab-distilleries .partnership-row .partnership-col {
  margin-top: 20px;
}

.tab-distilleries .partnership-row .partnership-col .partnership-div {
  background-color: lightblue;
}

@media (min-width: 992px) {
  .tab-distilleries .partnership-row .partnership-col {
    padding: 0px 30px 0px 30px;
  }

  .tab-distilleries .distilled-row, .tab-distilleries .consumer-row {
    margin-bottom: 150px;
  }
}

/* Partership item styles */

.tab-distilleries .partnership {
  color: white;
  background-color: #0d1218;
  height: 440px;
  transition: background-color 0.3s ease;
}

.tab-distilleries .partnership:hover {
  background-color: #1d2336;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px, rgba(0, 0, 0, 0.15) 0px 0px 1px;
}

.tab-distilleries .partnership .text {
  text-align: left;
  padding: 40px 30px 0px 30px;
}

.tab-distilleries .partnership .text h2 {
  font-size: 17px;
  font-family: "poppins-regular", Sans-Serif;
  padding-bottom: 10px;
  color: #ffffff;
}

.tab-distilleries .partnership .text p {
  font-size: 14px;
  font-family: "poppins-regular", Sans-Serif;
  opacity: 0.6;
  color: #ffffff;
}

.tab-distilleries .image-div {
  height: 200px;
  background-size: cover;
  background-position: center;
}

.tab-distilleries .arrow {
  position: absolute;
  left: 60px;
  bottom: 30px;
}

/* resize partnership column on narrow devices */
@media (max-width: 991px) {
  .tab-distilleries .arrow {
    position: absolute;
    left: 45px;
    bottom: 30px;
  }

  .tab-distilleries .explore-col {
    padding-left: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tab-distilleries .partnership .text {
    padding: 25px 20px 0px 20px;
  }

  .tab-distilleries .arrow {
    position: absolute;
    left: 50px;
  }
}

@media (max-width: 400px) {
  .tab-distilleries .image-div {
    height: 180px;
  }
}

@media (max-width: 340px) {
  .tab-distilleries .image-div {
    height: 170px;
  }
}

/* styles for beer stats */

.tab-distilleries .distillery-text {
  font-size: 30px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.tab-distilleries #border-top {
  background-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
  width: 23%;
  height: 3px;
  margin: auto;
  margin-top: 30px;
}

.tab-distilleries .stat {
  float: left;
  width: 33.3%;
}

.tab-distilleries .stats-row {
  margin-top: 50px;
  margin-bottom: 120px;
}

.tab-distilleries .stats-row .stats-col h2 {
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 46px;
  font-family: "poppins-medium", Sans-Serif;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#69a8f0),
    to(#2b5890)
  );
  background-image: linear-gradient(to right, #69a8f0, #2b5890);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tab-distilleries .stats-row .stats-col h3 {
  font-family: "poppins-regular", Sans-Serif;
  font-size: 16px;
}

@media (max-width: 350px) {
  .tab-distilleries .stats-row .stats-col h2 {
    font-size: 30px;
  }

  .tab-distilleries .stats-row .stats-col h3 {
    font-size: 11px;
  }
}

@media (min-width: 351px) and (max-width: 450px) {
  .tab-distilleries .stats-row .stats-col h2 {
    font-size: 32px;
  }

  .tab-distilleries .stats-row .stats-col h3 {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .tab-distilleries .stats-row {
    margin-bottom: 50px;
  }
}

/* Distillery management platform row */
.tab-distilleries .right-col {
  padding-left: 50px;
  padding-right: 0px;
}

.tab-distilleries .dmp-row .left-col {
  padding-top: 130px;
  padding-left: 0px;
}

.tab-distilleries .dmp-row .left-col h2 {
  padding-bottom: 10px;
}

.tab-distilleries .dmp-row .left-col .first-para {
  padding-top: 40px;
  font-family: "poppins-regular", Sans-Serif;
  font-size: 18px;
}

.tab-distilleries .dmp-row {
  text-align: left;
  margin-bottom: 150px;
}

.tab-distilleries .dmp-row #distillery-image {
  float: right;
  width: 85%;
  height: 700px;
  background-image: url(../../img/products/distilled-id/distillery.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.tab-distilleries .dmp-row #platform-image {
  position: relative;
  top: 7%;
  right: 23%;
  width: 100%;
  height: 100%;
  background-image: url("../../img/products/distilled-id/casks-demo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateZ(-800px) perspective(872px) rotateY(-368deg) scale(1) translateY(-21.6125px);
}

/* consumer row */
.tab-distilleries .consumer-row {
  text-align: left;
}

.tab-distilleries .consumer-row .left-col {
  padding-left: 0px;
  padding-right: 0px;
}

.tab-distilleries .consumer-row .right-col {
  padding-top: 140px;
  padding-left: 70px;
}

.tab-distilleries .consumer-row .right-col h2 {
  font-size: 30px;
  padding-right: 130px;
  padding-bottom: 10px;
}

.tab-distilleries .border-gradient {
  background-image: linear-gradient(to right, #5494de 0%, #2b5890 100%);
  width: 25%;
  height: 4px;
}

.tab-distilleries .consumer-row .right-col p {
  margin-bottom: 20px;
}

.tab-distilleries .consumer-row .right-col .first-para {
  font-family: "poppins-regular", Sans-Serif;
  font-size: 18px;
  padding-top: 40px;
}

.tab-distilleries .discover img {
  position: relative;
  bottom: 1px;
  left: 5px;
}

.tab-distilleries .dmp-row p.discover,
.tab-distilleries .consumer-row p.discover {
  padding-top: 50px;
  font-family: "poppins-medium", Sans-Serif;
}

.tab-distilleries .consumer-row #consumer-image {
  width: 92%;
  height: 700px;
  background-image: url(../../img/products/distilled-id/consumer-intelligence.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.tab-distilleries .consumer-row .ailsa-image-wrapper,
.tab-distilleries .platform-image-wrapper {
  height: 100%;
}

.tab-distilleries .consumer-row #ailsa-image {
  position: relative;
  top: 20%;
  left: 38%;
  width: 70%;
  height: 72%;
  background-image: url("../../img/products/distilled-id/ailsa-bay.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateZ(-800px) perspective(800px) rotateY(349deg) scale(0.9)
    translateY(4.1127px);
}

/* introducing distilled id row */

.tab-distilleries .distilled-row .left-col {
  text-align: left;
  padding-top: 80px;
  padding-left: 120px;
  padding-right: 60px;
}

.tab-distilleries .distilled-row .left-col .first-para {
  font-size: 20px;
}

.tab-distilleries .distilled-row .right-col {
  padding-left: 0px;
}

.tab-distilleries .distilled-row .distilled-inset {
  float: right;
  width: 100%;
  height: 460px;
  background-image: url(../../img/products/distilled-id/distilled-id-main@2x.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* "Get started with" inset */

.tab-distilleries .distilled-row .get-started {
  padding: 50px 50px 50px 50px;
  background-color: #0d1218;
  display: inline-block;
  color: #ffffff;
  height: 220px;
  position: relative;
  top: 60%;
  left: 15%;
}

.tab-distilleries .distilled-row .register {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: block;
}

.tab-distilleries .distilled-row .get-started h5 {
  font-family: "poppins-medium", Sans-Serif;
  font-size: 18px;
}

.tab-distilleries .distilled-row .get-started h3 {
  font-family: "poppins-medium", Sans-Serif;
}

.tab-distilleries .distilled-row .logo {
  float: left;
  transform: scale(0.9) translateX(-5%);
}

.tab-distilleries .distilled-inset h5 {
  font-family: "poppins-medium", Sans-Serif;
  font-size: 16px;
  padding-bottom: 5px;
}

.tab-distilleries #distilled-title {
  padding-bottom: 30px;
  font-size: 36px;
}

/* fonts styles */
.tab-distilleries .consumer-row h2,
.tab-distilleries .distilled-row h2,
.tab-distilleries .dmp-row h2 {
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.tab-distilleries .dmp-row p,
.tab-distilleries .consumer-row p,
.tab-distilleries .distilled-row p {
  font-size: 16px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.tab-distilleries .distilled-row #trusted {
  font-family: "nanum-gothic-bold", Sans-Serif;
  margin-top: 25px;
}

.tab-distilleries a:hover {
  text-decoration: none;
}

.tab-distilleries .logos {
  margin-bottom: 30px;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.tab-distilleries .logo-image {
  margin: 5px;
  background-repeat: no-repeat;
}

.tab-distilleries .logos #logo2 {
  background-image: url(../../img/client-logos/distilled-id/blackwater-logo-s.svg);
  width: 50px;
  height: 50px;
}

.tab-distilleries .logos #logo3 {
  background-image: url(../../img/client-logos/distilled-id/AB-logo-s.svg);
  width: 40px;
  height: 30px;
}

.tab-distilleries .logos #logo4 {
  background-image: url(../../img/client-logos/distilled-id/ICB-logo-s.svg);
  width: 67px;
  height: 38px;
}

.tab-distilleries .logos #logo5 {
  background-image: url(../../img/client-logos/distilled-id/WGS-logo-s.svg);
  width: 200px;
  height: 28px;
}

/* Styles for narrow screens */
@media (max-width: 991px) {
  /* padding */
  .tab-distilleries .distilled-row .left-col,
  .tab-distilleries .consumer-row .right-col,
  .tab-distilleries .dmp-row .left-col {
    padding-left: 30px;
    padding-right: 30px;
  }

  .tab-distilleries .distilled-row, .tab-distilleries .consumer-row {
    margin-bottom: 50px;
  }

  .tab-distilleries .distilled-row .left-col,
  .tab-distilleries .consumer-row .right-col,
  .tab-distilleries .dmp-row .left-col {
    padding-top: 0px;
  }

  .tab-distilleries p.discover {
    padding-top: 0px;
    padding-bottom: 20px;
  }
}

@media (max-width: 550px) {
  .tab-distilleries .consumer-row .right-col h2 {
    padding-right: initial;
  }

  .tab-distilleries .quote-div h2 {
    font-size: 16px;
    line-height: 1.75em;
  }

  .tab-distilleries .quote-div h3 {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .tab-distilleries .distilled-row .get-started {
    left: 10%;
  }
}

/* images */
@media (max-width: 600px) {
  .tab-distilleries .dmp-row #distillery-image {
    width: 85%;
    height: 260px;
  }

  .tab-distilleries .dmp-row #platform-image {
    top: 17%;
    right: 23%;
    width: 100%;
    height: 80%;
  }

  .tab-distilleries .consumer-row #consumer-image {
    width: 92%;
    height: 250px;
    border-radius: 5px;
  }

  .tab-distilleries .consumer-row #ailsa-image {
    top: 20%;
    left: 28%;
    width: 70%;
    height: 102%;
  }

  .tab-distilleries .distilled-row .distilled-inset {
    height: 250px;
  }

  .tab-distilleries .distilled-row .get-started {
    padding: 25px;
    height: 150px;
  }

  .tab-distilleries .right-col {
    margin-top: 20px !important;
  }

  .tab-distilleries .dmp-row {
    margin-bottom: 70px;
  }

  .tab-distilleries .partnership-row {
    padding-bottom: 70px;
  }

  .tab-distilleries .quote-div {
    padding: 50px 10px;
  }
}

/* mobile spacing */
@media (max-width: 991px) {
  .tab-distilleries .right-col h2 {
    margin-top: 50px;
  }

  .tab-distilleries .consumer-row .left-col {
    margin-top: 50px;
  }

  .tab-distilleries .right-col {
    margin-top: 50px;
  }
}
