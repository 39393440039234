.solutions {
  padding-top: 100px;
}

.solutions h2 {
  padding-bottom: 40px;
  font-size: 40px;
  font-family: "nanum-gothic-regular", Sans-Serif;
}

.solutions .tab {
  float: left;
}

.solutions .tab-container {
  display: inline-block;
}

.solutions .nav {
  width: 100%;
}

.solutions .nav-link {
  font-size: 18px;
  color: #8f94a0;
  transition: all 0.3s ease-in;
}

.solutions .nav-link:hover {
  color: #5a6274;
}

.solutions .nav-link::after {
  display: block;
  background-image: linear-gradient(to right, #dee1e9 0%, #dee1e9 100%);
  content: "";
  height: 2px;
  position: relative;
  bottom: -10px;
}

.solutions .nav-link.active {
  color: #4882c7;
  font-weight: 500;
}

.solutions .nav-link.active::after {
  display: block;
  background-image: linear-gradient(to right, #4882c7 0%, #4882c7 100%);
  content: "";
  height: 2px;
  position: relative;
  bottom: -10px;
}

.solutions .nav-item {
  display: inline-block;
}

.solutions .tab-content-row {
  margin-top: 50px;
}

@media (min-width: 1200px) {
  .solutions .nav-link {
    width: 250px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .solutions .nav-link {
    width: 225px;
  }
}

@media (max-width: 991px) {
  .solutions .nav-link {
    padding-top: 20px;
    width: 220px;
  }
}


@media (max-width: 600px) {
  .solutions h2 {
    padding-bottom: 20px;
  }

  .solutions .tab-row {
    padding: 0px !important;
  }

  .solutions .nav-link {
    width: 300px;
  }
}

.solutions .tab-row {
  padding: 0px 50px 0px 50px;
}

/* remove tab-content-row gutters */

.solutions .tab-content-row .no-margin {
  padding-left: 0px !important;
  padding-right: 0px !important;
}