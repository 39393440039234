@font-face {
  font-family: "poppins-light";
  src: local("poppins-light"),
    url(./res/font/Poppins-Light.ttf) format("truetype");
}
@font-face {
  font-family: "poppins-regular";
  src: local("poppins-regular"),
    url(./res/font/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "poppins-medium";
  src: local("poppins-medium"),
    url(./res/font/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "poppins-bold";
  src: local("poppins-bold"),
    url(./res/font/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "nanum-gothic-regular";
  src: local("nanum-gothic-regular"),
    url(./res/font/NanumGothic-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "nanum-gothic-bold";
  src: local("nanum-gothic-bold"),
    url(./res/font/NanumGothic-Bold.ttf) format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.arrow-link-wrapper:hover {
  text-decoration: none;
}

.arrow-link-wrapper {
  display: inline-block;
  font-family: "poppins-bold", Sans-Serif;
  position: relative;
}

.arrow-link-wrapper,
.arrow-link-wrapper:before,
.arrow-link-wrapper:after {
  transition: 1000ms;
  -webkit-transition: 1000ms;
  -ms-transition: 1000ms;
}

.arrow-link-wrapper:before {
  content: attr(data-link-alt);
  position: absolute;
  left: 0px;
  top: 50px;
  white-space: nowrap;
  width: 0px;
  overflow: hidden;
  text-align: initial;
  transition: 3000ms;
  -webkit-transition: 3000ms;
  -ms-transition: 3000ms;
}

.arrow-link {
  display: inline-block;
}

.arrow-link-wrapper:hover:before {
  width: 10%;
}

.arrow-link::after {
  content: "";
  display: inline-block;
  position: relative;
  margin-left: 0.75rem;
  vertical-align: baseline;
  width: 20px;
  height: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.4s
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-image: url(./img/icons/discover-arrow.svg);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.arrow-link:hover::after,
a.arrow-link:focus::after,
a:hover > .arrow-link::after,
a:focus > .arrow-link::after {
  -webkit-transform: translate(7px, 0);
  transform: translate(7px, 0);
}

.arrow-link.dark {
  font-size: 15px;
  color: #363f54;
}

.arrow-link.dark:hover {
  color: #000;
}

.arrow-link.light {
  font-size: 15px;
  color: #eee;
}

.arrow-link.light:hover {
  color: #fff;
}

@media (max-width: 992px) {
  .navigation-menu .arrow-link::after {
    display: none;
  }
}

.button-hover {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
}

.button-hover:hover {
  background-position: 100% 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0d1218),
    color-stop(#2b4b70),
    color-stop(#4882c7),
    to(#346daf)
  ) !important;
  background-image: linear-gradient(
    to right,
    #0d1218,
    #2b4b70,
    #4882c7,
    #346daf
  ) !important;
}

.button-hover:focus {
  outline: none;
}

.shadow-l {
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.03),
    0 20px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.03), 0 20px 30px rgba(0, 0, 0, 0.1);
}

a {
  cursor: pointer;
}
